<template>
  <div>
    <div style="text-align: center" v-if="usersLoading">
      <c-spinner ml="2" color="orange" thickness="2px" />
    </div>

    <div v-if="!noMessage" class="user_container">
      <div v-for="(user, index) in userMessages" :key="index + 57876">
        <c-box
          v-if="!user.sameUserMessages"
          d="flex"
          alignItems="center"
          justify-content="space-between"
          :class="
            currentRelationshipID === user.id
              ? 'activeUser user_list'
              : 'user_list'
          "
        >
          <div class="user" @click="onSelectedUser(user)">
            <div class="chat_user">
              <img
                class="members"
                :src="
                  user.person2.profilePhoto
                    ? user.person2.profilePhoto
                    : placeHolderImage
                "
              />
              <span
                class="online_dot"
                v-if="user.person2.OnlineStatus == 'true'"
              ></span>
            </div>

            <div class="details">
              <div class="name_sec">
                <h3>
                  {{ user.name }}
                </h3>
                <!-- <small>{{ user.unReadMessageCount }}</small> -->
                <span class="cus_badge" v-if="user.unReadMessageCount > 0">
                  {{ user.unReadMessageCount }}
                </span>
                <small v-else>{{ user.chat_time }}</small>
              </div>

              <p>
                <small v-if="user.lastMessageIsYou">You:</small>
                {{ user.lastMessage }}
              </p>
            </div>
          </div>
        </c-box>
      </div>
    </div>

    <div v-if="noMessage" class="mt-5 noMessage">
      <img
        src="@/assets/icons/sdg/nomessage.svg"
        alt="no_message"
        width="100px"
      />

      <h2 style="font-size: 12px">No Conversations Yet</h2>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';
import {
  getUserChatList,
  subscribeToChatMessageList,
  markMessageAsRead,
} from '@/services/messages.js';
import { refreshToken } from '@/services/auth.js';
import { onLogin, onLogout } from '@/vue-apollo.js';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import { getDateFormat } from '@/helpers/utils.js';

export default {
  name: 'UsersMessageList',
  data() {
    return {
      placeHolderImage,
      currentRelationshipID: 0,
      noMessage: true,
      userMessages: [],
      usersLoading: true,
      authUserId: store.state.auth.user.id,
    };
  },
  mounted() {
    this.subscribeToUserMessagesList();
  },
  methods: {
    ...mapActions({
      setSelectedUserChat: 'messaging/setSelectedUserChat',
      addMessageNotification: 'messaging/addMessageNotification',
      clearMessageNotification: 'messaging/clearMessageNotification',
      clearAllMessageNotification: 'messaging/clearAllMessageNotification',
    }),
    getDateFormat,
    subscribeToUserMessagesList() {
      const loggedInUserId = store.state.auth.user.id;
      subscribeToChatMessageList(loggedInUserId).subscribe({
        next: (res) => {
          const userMessages = res.data.personal_chat_relationship;
          console.log('subscribed msg list');
          this.formatUserMessages(userMessages, 'subscribe');
        },
        error(error) {
          this.usersLoading = false;
          console.error(error);
        },
      });

      // check if data hasn't loaded after 30 seconds and reconnect
      setTimeout(async () => {
        if (this.usersLoading === true) {
          console.log('reconnecting');
          // this.getUserMessagesList();
          const payload = {
            refresh_token: store.state.auth.token.refreshToken,
          };

          try {
            const response = await refreshToken(payload);
            await onLogin(
              this.$apollo.provider.defaultClient,
              response.data.tokenDetails
            );
            this.subscribeToUserMessagesList();
          } catch (error) {
            console.log(error);
            await onLogout(this.$apollo.provider.defaultClient);
            this.$router.push('/auth/login');
          }
        }
      }, 10000);
    },
    async getUserMessagesList() {
      const loggedInUserId = store.state.auth.user.id;

      try {
        const response = await getUserChatList(loggedInUserId);

        const userMessages = response.data.personal_chat_relationship;

        this.formatUserMessages(userMessages, 'history');

        this.usersLoading = false;
      } catch (error) {
        this.usersLoading = false;
        console.log(error);
      }
    },

    formatUserMessages(userMessages, type) {
      console.log(type);
      if (Object.keys(userMessages).length === 0) {
        this.noMessage = true;
      } else {
        this.noMessage = false;

        const formattedUsers = userMessages.map((user) => {
          return {
            ...user,
            isChannel: false,
            chat_time: this.getDateFormat(user.last_chat_time),
            sameUserMessages: user.person1.id == user.person2.id,
            lastMessageIsYou: user.messages[0].sender.id === this.authUserId,
            person2:
              user.person2.id == this.authUserId ? user.person1 : user.person2,
            name:
              user.person2.id == this.authUserId
                ? user.person1.firstname
                  ? user.person1.firstname + ' ' + user.person1.lastname
                  : 'No name'
                : user.person2.firstname
                ? user.person2.firstname + ' ' + user.person2.lastname
                : 'No name',
            lastMessage: user.messages[0].content,
            lastMessageObject: user.messages[0],
            unReadMessageCount: user.messages_aggregate.aggregate.count,
            messageTotal:
              user.messages[0].relation.messages_aggregate.aggregate.count,
          };
        });

        // formattedUsers.forEach((user) => {
        //   user.messages.forEach((message) => {
        //     if (
        //       message.IsRead !== true &&
        //       message.sender.id !== this.authUserId &&
        //       type === 'subscribe'
        //     ) {
        //       this.addMessageNotification({ toast: this.$toast, message });
        //     }
        //   });
        // });

        this.userMessages = formattedUsers;
        this.usersLoading = false;
      }
    },
    async onSelectedUser(userData) {
      const user = {
        name: userData.name,
        id: userData.person2.id,
        img: userData.person2.profilePhoto,
        isChannel: false,
        messageTotal: userData.messageTotal,
        OnlineStatus: userData.person2.OnlineStatus,
      };

      if (userData.unReadMessageCount > 0) {
        await this.markMessageAsRead(
          userData.lastMessageObject.id,
          userData.person2.id
        );
      }

      this.clearMessageNotification({
        id: userData.lastMessageObject.id,
        creatorId: userData.lastMessageObject.creatorId,
      });

      if (this.currentRelationshipID !== userData.id) {
        this.currentRelationshipID = userData.id;
        this.setSelectedUserChat(user);

        if (
          this.$route.name == 'UserMessaging' ||
          this.$route.name == 'ChannelMessaging' ||
          this.$route.name == 'messaging' ||
          this.$route.name == 'CreateNewChannel'
        ) {
          this.goToMessageRoute(user);
        } else {
          this.$emit('sendFooterMessage', userData);
        }
      } else {
        this.$emit('sendFooterMessage', userData);
      }
    },
    goToMessageRoute(user) {
      const targetRoute = {
        name: 'UserMessaging',
        query: { name: user.name },
      };

      if (
        this.$route.path !== targetRoute.path ||
        JSON.stringify(this.$route.query) !== JSON.stringify(targetRoute.query)
      ) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    async markMessageAsRead(lastMessageId, senderId) {
      const recieverId = store.state.auth.user.id;

      try {
        await markMessageAsRead({ lastMessageId, senderId, recieverId });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user_container {
  margin-top: 15px;

  .activeUser {
    background: #e4eaf5;
    border-radius: 3px;
  }

  .user_list {
    padding-right: 10px;
    &:hover {
      background: #e4eaf5;
      border-radius: 3px;
    }
    .user {
      display: flex;
      margin-bottom: 3px;
      cursor: pointer;
      padding: 8px 5px;

      .chat_user {
        display: flex;
        flex-shrink: 0;

        img {
          margin-right: 10px;
          width: 45px;
          height: 45px;
          object-fit: fill;
        }
        img.members {
          border-radius: 8px;
        }

        .online_dot {
          height: 12px;
          width: 12px;
          background-color: #088b15;
          border: 2px solid white;
          border-radius: 50%;
          display: inline-block;
          margin: 33px 0 0 -18px;
        }
      }

      .details {
        .name_sec {
          display: flex;
          align-items: center;

          h3 {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: var(--color-primary);

            width: 154px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          small {
            font-size: 9px;
          }
        }

        p {
          margin-top: 3px;
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: var(--color-primary);

          height: 26px;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;

          small {
            font-weight: bold;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.noMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
